import React from "react";
import "./Background.css";

const Background = () => {
  return (
    <div className="container">
    </div>
  );
};

Background.propTypes = {};

export default Background;
